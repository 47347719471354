import React, {ComponentProps, useMemo} from 'react'
import {defineMessages} from 'react-intl'
import {useSelector} from 'react-redux'
import {SplitBookingBundleType} from 'components/Offer/types'
import {useShouldOfferLeadToRooms} from 'hooks/useShouldOfferLeadToRooms'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {getIsLockedDealExcludingGHA} from 'modules/search/selectors'

import {Button, Props as ButtonProps} from '@daedalus/atlas/Button'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Lock} from '@daedalus/atlas/helpers/Lock'
import {Icon} from '@daedalus/atlas/Icon'
import {getIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'

interface BaseCTAProps {
  /** The offer object */
  offer: SearchOffer
  /** Whether the button full width of parent */
  fullWidth?: boolean
  /** Whether the locked icon should be shown */
  showLockIcon?: boolean
  /** Whether the button is in the loading state */
  loading?: ButtonProps['loading']
  /** Whether the offer should be locked to web users */
  isAppLocked?: boolean
  /** Whether the offer should be locked under OneTimePasswordFlow */
  isRequiredOneTimePasswordFlow?: boolean
  /** Whether the button should use the secondary variant */
  withSecondaryVariant?: boolean
}

interface SplitBookingCTAProps {
  /** Whether the button full width of parent */
  fullWidth?: boolean
  /** Whether the locked icon should be shown */
  showLockIcon?: boolean
  /** Whether the button is in the loading state */
  loading?: ButtonProps['loading']
  /** Whether the offer should be locked to web users */
  isAppLocked?: boolean
  /** The split booking type */
  splitBookingType: SplitBookingBundleType
}

const ctaMessages = defineMessages({
  unlock: {
    id: 'signUpCTAUnlock',
    defaultMessage: 'Unlock'
  },
  viewDeal: {
    id: 'viewDeal',
    defaultMessage: 'View Deal'
  },
  static: {
    id: 'checkAvailability',
    defaultMessage: 'Check availability'
  },
  viewRooms: {
    id: 'accommodation.viewRooms',
    defaultMessage: 'View rooms'
  },
  appLockedExclusiveV2: {
    id: 'offer.appExclusiveCta',
    defaultMessage: 'View on app'
  }
})

const getIcon = ({
  shouldShowLock,
  isLocked,
  isAppLocked
}: {
  shouldShowLock: boolean
  isAppLocked: boolean
  isLocked: boolean
}) => {
  if (isAppLocked) {
    return <Icon name="Iphone" />
  }
  if (shouldShowLock) {
    return <Lock size={20} isLocked={isLocked} />
  }
}

const SplitBookingCTA = ({
  loading = false,
  fullWidth = false,
  showLockIcon = false,
  isAppLocked = false,
  splitBookingType
}: SplitBookingCTAProps) => {
  const shouldGoToRooms = useShouldOfferLeadToRooms()

  const ctaMessage = () => {
    if (isAppLocked) return ctaMessages.appLockedExclusiveV2
    if (shouldGoToRooms && splitBookingType === 'single_flow')
      return ctaMessages.viewRooms

    return ctaMessages.viewDeal
  }
  const showChevronIcon = !isAppLocked && !shouldGoToRooms

  const CtaProps: ComponentProps<typeof Button> = {
    ...(isAppLocked
      ? {
          dataId: 'PrivateDealCTA',
          variant: 'special',
          showIcon: showChevronIcon
        }
      : {
          dataId: 'OfferCTA',
          showIcon: showChevronIcon,
          variant: 'primary'
        })
  }

  return (
    <Button
      {...CtaProps}
      size="lg"
      fullWidth={fullWidth}
      loading={loading}
      iconStart={
        <Flex.Row>
          {getIcon({
            shouldShowLock: showLockIcon,
            isAppLocked,
            isLocked: false
          })}
        </Flex.Row>
      }
      iconEnd={
        showChevronIcon && (
          <Box hasMirror>
            <Icon name="ChevronRight" />
          </Box>
        )
      }
    >
      <FormattedMessageWrapper {...ctaMessage()} />
    </Button>
  )
}

const BaseCTA = ({
  offer,
  fullWidth = false,
  showLockIcon = false,
  loading,
  isAppLocked = false,
  isRequiredOneTimePasswordFlow,
  withSecondaryVariant = false
}: BaseCTAProps) => {
  const isPrivate = useSelector(getIsPrivateDeal(offer))
  const isBofhOffer = getIsBofhOffer(offer)

  const shouldGoToRooms = useShouldOfferLeadToRooms(isBofhOffer)

  const isLocked =
    useSelector(getIsLockedDealExcludingGHA(offer)) ||
    isRequiredOneTimePasswordFlow

  const showChevronIcon =
    !(isPrivate && isLocked) && !isAppLocked && !shouldGoToRooms

  const ctaMessage = () => {
    if (isAppLocked) return ctaMessages.appLockedExclusiveV2
    if (isLocked) return ctaMessages.unlock
    if (shouldGoToRooms) return ctaMessages.viewRooms
    return ctaMessages.viewDeal
  }

  const CtaProps: ComponentProps<typeof Button> = useMemo(() => {
    // applies the secondary variant to the offer CTA when it is displayed alongside special CTAs
    // this includes scenarios where it is alongside the 'Deal Freeze' button or the 'Web2App' promotion
    if (withSecondaryVariant) {
      return {
        dataId: 'OfferCTA',
        variant: 'primary'
      }
    }

    if (isPrivate || isAppLocked || isRequiredOneTimePasswordFlow) {
      return {
        dataId: 'PrivateDealCTA',
        variant: 'primary'
      }
    }

    return {
      dataId: 'OfferCTA',
      variant: 'primary'
    }
  }, [
    withSecondaryVariant,
    isAppLocked,
    isPrivate,
    isRequiredOneTimePasswordFlow
  ])

  const shouldShowLock =
    isRequiredOneTimePasswordFlow || (showLockIcon && isPrivate)

  return (
    <Button
      {...CtaProps}
      size="lg"
      fullWidth={fullWidth}
      loading={loading}
      iconStart={
        <Flex.Row>{getIcon({shouldShowLock, isAppLocked, isLocked})}</Flex.Row>
      }
      iconEnd={
        showChevronIcon && (
          <Box hasMirror>
            <Icon name="ChevronRight" />
          </Box>
        )
      }
    >
      {isRequiredOneTimePasswordFlow ? (
        <FormattedMessageWrapper
          id="oneTimePasswordFlow.buttonCta"
          defaultMessage="Get code"
        />
      ) : (
        <FormattedMessageWrapper {...ctaMessage()} />
      )}
    </Button>
  )
}

type Props = (BaseCTAProps | SplitBookingCTAProps) & {
  splitBookingType: SplitBookingBundleType
}

export const OfferCTA = (props: Props) => {
  if (props.splitBookingType) {
    return <SplitBookingCTA {...props} />
  }

  return <BaseCTA {...(props as BaseCTAProps)} />
}
