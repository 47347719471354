import React, {FC, useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useTrackOfferView} from 'hooks/analytics'
import {useIsRequiredOneTimePasswordFlow} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {useOfferClickHandler} from 'hooks/useOfferClickHandler'
import {useSapi} from 'modules/sapiSearch/SapiProvider'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'
import {getIsLockedDealExcludingGHA} from 'modules/search/selectors'
import {getHotelApiRoomsParameters} from 'modules/searchApi/selectors'
import {toggle} from 'opticks'

import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {getOfferHelperClasses} from '@daedalus/core/src/offer/business/privateDeals'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {LockedDeal} from './LockedDeal'
import {OfferLink} from './OfferLink'

interface Props {
  anchorPriceTotal: number
  component: string
  hotelId: string
  offer: SearchOffer
  offerPosition: number
  onClick?: (offer: SearchOffer) => void
  isDiscount?: boolean
  isSplitBooking?: boolean
  hasInteraction?: boolean
}

const preHeatedOffers: Record<string, boolean> = {}

/**
 * A wrapper component that handles all interactions on offers
 * Avoid adding styling here, it should transparently add interactivity
 */
export const OfferInteraction: FC<Props> = ({
  children,
  anchorPriceTotal,
  component,
  hotelId,
  offer,
  offerPosition,
  onClick,
  isDiscount,
  isSplitBooking,
  hasInteraction = true
}) => {
  const {sapiClient} = useSapi()
  const isLocked =
    useSelector(getIsLockedDealExcludingGHA(offer)) && !isSplitBooking
  const {handleClick} = useOfferClickHandler({
    component,
    hotelId,
    offer,
    offerPosition,
    onClick,
    isDiscount,
    isSplitBooking
  })

  const isBofhOffer = getIsBofhOffer(offer)

  const roomsEndpointParams = useSelector(state =>
    getHotelApiRoomsParameters(state, hotelId)
  )
  const preHeatRoomOffers = useCallback(() => {
    if (
      isBofhOffer &&
      toggle('c04d87a7-preheat-sapi-rooms', false, true) &&
      !preHeatedOffers[offer?.id]
    ) {
      sapiClient.rooms({
        ...roomsEndpointParams,
        providerCode: offer.providerCode,
        clickedOfferId: offer.id,
        clickedOfferBaseRate: offer.rate.base,
        clickedOfferTaxes: offer.rate.taxes,
        clickedOfferHotelFees: offer.rate.hotelFees,
        preHeat: 1
      })
      preHeatedOffers[offer?.id] = true
    }
  }, [isBofhOffer, sapiClient, roomsEndpointParams, offer])

  const inViewRef = useTrackOfferView(offer, hotelId, component)
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isRequiredOneTimePasswordFlow = useIsRequiredOneTimePasswordFlow({
    offer,
    isAppLockedDeal
  })

  if (!hasInteraction) return <>{children}</>

  if ((isLocked && !isAppLockedDeal) || isRequiredOneTimePasswordFlow) {
    const dataId = isBofhOffer ? 'BofhOffer--locked' : 'ProviderOffer--locked'

    return (
      <LockedDeal
        inViewRef={inViewRef}
        role="button"
        dataId={dataId}
        onClick={onClick}
        component={component}
        hotelId={hotelId}
        offer={offer}
        offerPosition={offerPosition}
        isDiscount={isDiscount}
        isSplitBooking={isSplitBooking}
      >
        {children}
      </LockedDeal>
    )
  }

  const dataId = isBofhOffer ? 'BofhOffer' : 'ProviderOffer'
  const addNoReferrer = isBofhOffer ? '' : 'noreferrer'

  return (
    <OfferLink
      inViewRef={inViewRef}
      className={getOfferHelperClasses(offer)}
      addNoReferrer={addNoReferrer}
      dataId={dataId}
      onHover={preHeatRoomOffers}
      handleClick={handleClick}
      anchorPriceTotal={anchorPriceTotal}
      offer={offer}
      hotelId={hotelId}
    >
      {children}
    </OfferLink>
  )
}
