import {createSelector} from '@reduxjs/toolkit'

import {brands} from '../../../brand/config'
import {BrandCode} from '../../../brand/types'
import {
  checkIsBrand85off,
  checkIsBrandCheckMyBus,
  checkIsBrandDealbase,
  checkIsBrandDemo,
  checkIsBrandDemoBasic,
  checkIsBrandGoSeek,
  checkIsBrandInternal,
  checkIsBrandKiwi,
  checkIsBrandOffersLockedByDefault,
  checkIsBrandVio,
  checkIsCookieConsentEnabledForBrand,
  getLocalizedContactUrl,
  getVioBrand
} from '../../../brand/utils'

export interface State {
  meta: {
    brandCode: BrandCode
  }
}

/**
 * Gets the meta information from the state
 * @param state - The application state
 * @returns The meta object
 */
const getMeta = (state: State) => state.meta

/**
 * Selector to get the brand code from meta
 * @param state - The application state
 * @returns The brand code
 */
const getBrandCodeFromMeta = createSelector([getMeta], meta => meta.brandCode)

/**
 * Selector to get the brand from state
 * @param state - The application state
 * @returns The brand object
 */
export const getBrand = createSelector(
  [getBrandCodeFromMeta],
  brandCode => brands[brandCode]
)

/**
 * Selector to get the brand code
 * @param state - The application state
 * @returns The brand code
 */
export const getBrandCode = createSelector([getBrand], brand => brand.code)

/**
 * Selector to get the brand name
 * @param state - The application state
 * @returns The brand name
 */
export const getBrandName = createSelector([getBrand], brand => brand.name)

/**
 * Selector to get the brand theme
 * @param state - The application state
 * @returns The brand theme
 */
export const getBrandTheme = createSelector([getBrand], brand => brand.theme)

/**
 * Selector to get the brand address
 * @param state - The application state
 * @returns The brand address
 */
export const getBrandAddress = createSelector(
  [getBrand],
  brand => brand.siteAddresses.address
)

/**
 * Selector to get the brand provider code
 * @param state - The application state
 * @returns The brand provider code
 */
export const getBrandProviderCode = createSelector(
  [getBrand],
  brand => brand.providerCode
)

/**
 * Selector to get the brand SAPI code
 * @param state - The application state
 * @returns The brand SAPI code or brand code if SAPI code is not available
 */
export const getBrandSapiCode = createSelector(
  [getBrand],
  brand => brand.sapiCode || brand.code
)

/**
 * Selector to check if the brand is Vio
 * @param state - The application state
 * @returns True if the brand is Vio, false otherwise
 */
export const getIsBrandVio = createSelector([getBrand], brand =>
  checkIsBrandVio(brand)
)

/**
 * Selector to check if the brand is a partner
 * @param state - The application state
 * @returns True if the brand is a partner, false if internal
 */
export const getIsBrandPartner = createSelector(
  [getBrand],
  brand => !checkIsBrandInternal(brand)
)

/**
 * Selector to check if the brand is Kiwi
 * @param state - The application state
 * @returns True if the brand is Kiwi, false otherwise
 */
export const getIsBrandKiwi = createSelector([getBrand], brand =>
  checkIsBrandKiwi(brand)
)

/**
 * Selector to check if the brand is Dealbase
 * @param state - The application state
 * @returns True if the brand is Dealbase, false otherwise
 */
export const getIsBrandDealbase = createSelector([getBrand], brand =>
  checkIsBrandDealbase(brand)
)

/**
 * Selector to check if the brand is GoSeek
 * @param state - The application state
 * @returns True if the brand is GoSeek, false otherwise
 */
export const getIsBrandGoseek = createSelector([getBrand], brand =>
  checkIsBrandGoSeek(brand)
)

/**
 * Selector to check if the brand is Demo Basic
 * @param state - The application state
 * @returns True if the brand is Demo Basic, false otherwise
 */
export const getIsBrandDemoBasic = createSelector([getBrand], brand =>
  checkIsBrandDemoBasic(brand)
)

/**
 * Selector to check if the brand is 85off
 * @param state - The application state
 * @returns True if the brand is 85off, false otherwise
 */
export const getIsBrand85off = createSelector([getBrand], brand =>
  checkIsBrand85off(brand)
)

/**
 * Selector to check if cookie consent is enabled for the brand
 * @param state - The application state
 * @returns True if cookie consent is enabled for the brand, false otherwise
 */
export const getIsCookieConsentEnabledForBrand = createSelector(
  [getBrand],
  brand => checkIsCookieConsentEnabledForBrand(brand)
)

/**
 * Selector to check if offers are locked by default for the brand
 * @param state - The application state
 * @returns True if offers are locked by default for the brand, false otherwise
 */
export const getIsBrandOffersLockedByDefault = createSelector(
  [getBrand],
  brand => checkIsBrandOffersLockedByDefault(brand)
)

/**
 * Selector to get the brand's sign-in display setting
 * @param state - The application state
 * @returns The brand's sign-in display setting
 */
export const getBrandShowSignIn = createSelector(
  [getBrand],
  brand => brand.showSignIn
)

/**
 * Selector to get the brand's widget header message
 * @param state - The application state
 * @returns The brand's widget header message
 */
export const getBrandWidgetHeaderMessage = createSelector(
  [getBrand],
  brand => brand.messages.widgetHeaderMessage
)

/**
 * Selector to get the brand's private deal message
 * @param state - The application state
 * @returns The brand's private deal message
 */
export const getBrandPrivateDealMessage = createSelector(
  [getBrand],
  brand => brand.messages.privateDealMessage
)

/**
 * Selector to check if internal redirect should be hidden for the brand
 * @param state - The application state
 * @returns True if internal redirect should be hidden, false otherwise
 */
export const getBrandHideInternalRedirect = createSelector(
  [getBrand],
  brand => brand.hideInternalRedirect
)

/**
 * Selector to get the brand's contact URL
 * @param state - The application state
 * @returns The brand's contact URL
 */
export const getBrandContactUrl = createSelector(
  [getBrand],
  brand => brand.links.contactUrl
)

/**
 * Selector to get all brand properties and feature flags
 * @param state - The application state
 * @returns An object containing all brand properties and feature flags
 */
export const getBrandProperties = createSelector([getBrand], brand => {
  const vioBrand = getVioBrand()

  const bookOnBrandEnabled = brand.bookOnBrand?.enabled
  const brandCode = brand.code
  const brandContactUrl = getLocalizedContactUrl(brand)
  const brandCookieConsentId = brand.cookieConsentId
  const brandEnabledFilters = brand.enabledFilters
  const brandHelpCenterChatKey = brand.bookOnBrand?.helpCenterChatKey
  const brandHomepageHeadingMessage = brand.messages.homepageHeadingMessage
  const brandHomepageSubheadingMessage =
    brand.messages.homepageSubheadingMessage
  const brandIsCookieConsentEnabled = checkIsCookieConsentEnabledForBrand(brand)
  const brandIsDealbase = checkIsBrandDealbase(brand)
  const brandIsCheckMyBus = checkIsBrandCheckMyBus(brand)
  const brandIsInternal = checkIsBrandInternal(brand)
  const brandIsKiwi = checkIsBrandKiwi(brand)
  const brandIsVio = checkIsBrandVio(brand)
  const brandLevelIsBasic = brand.level === 'basic'
  const brandIsDemo = checkIsBrandDemo(brand)
  const brandMainHotelImageIndex = brand.mainHotelImageIndex
  const brandName = brand.name
  const brandOffersLockedByDefault = checkIsBrandOffersLockedByDefault(brand)
  const brandPageTitleMessage = brand.messages.pageTitleMessage
  const brandPageTitleTagLineMessage = brand.messages.pageTitleTagLineMessage
  const brandPrivateDealMessage = brand.messages.privateDealMessage
  const brandShareUrl = brand.socials?.shareUrl
  const brandShowSignIn = brand.showSignIn
  const brandSiteAddress = brand.siteAddresses.address
  const brandThemeName = brand.theme

  // Brand feature flags
  const brandHasAlternativeSearchForm =
    brandIsDealbase || brandIsKiwi || brandIsCheckMyBus
  const brandHasCollapsingHeader =
    brandIsDealbase || brandIsKiwi || brandIsCheckMyBus
  const brandHasExitPopup = !brandIsDealbase && !brandIsKiwi
  const brandHasMap = !brandLevelIsBasic && !brandIsDealbase
  const brandHasDesktopMoreDealsButton = !brandLevelIsBasic && !brandIsDealbase
  const brandHasFilters = !brandLevelIsBasic
  const brandHasSort = !brandLevelIsBasic

  return {
    bookOnBrandEnabled,
    brand,
    brandCode,
    brandContactUrl,
    brandCookieConsentId,
    brandEnabledFilters,
    brandHasAlternativeSearchForm,
    brandHasCollapsingHeader,
    brandHasExitPopup,
    brandHelpCenterChatKey,
    brandHomepageHeadingMessage,
    brandHomepageSubheadingMessage,
    brandIsCookieConsentEnabled,
    brandIsDealbase,
    brandIsCheckMyBus,
    brandIsInternal,
    brandIsKiwi,
    brandIsVio,
    brandIsDemo,
    brandLevelIsBasic,
    brandMainHotelImageIndex,
    brandName,
    brandOffersLockedByDefault,
    brandPageTitleMessage,
    brandPageTitleTagLineMessage,
    brandPrivateDealMessage,
    brandShareUrl,
    brandShowSignIn,
    brandSiteAddress,
    brandThemeName,
    vioBrand,
    brandHasFilters,
    brandHasSort,
    brandHasMap,
    brandHasDesktopMoreDealsButton
  }
})
