import {useSelector} from 'react-redux'
import {
  getAppLockedDealConfig,
  getOfferHasGhaOrGvrPreferredRate,
  getShowTotalPrices,
  getShowTotalPriceToggle
} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {getIsLockedDealExcludingGHA} from 'modules/search/selectors'

export const useShowTotalPriceGhaOrGvrPreferredRate = (
  offer: SearchOffer,
  hotelId: string,
  isRequiredOneTimePasswordFlow: boolean
) => {
  const isLockedDeal = useSelector(getIsLockedDealExcludingGHA(offer))
  const showTotalPrices = useSelector(getShowTotalPrices)
  const showTotalPriceToggle = useSelector(getShowTotalPriceToggle)

  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const shouldShowTotalPrice =
    isLockedDeal || isAppLockedDeal || isRequiredOneTimePasswordFlow

  const offerHasGhaOrGvrPreferredRate = useSelector(state =>
    getOfferHasGhaOrGvrPreferredRate(state, offer)
  )

  return (
    shouldShowTotalPrice &&
    offerHasGhaOrGvrPreferredRate &&
    !showTotalPrices &&
    showTotalPriceToggle
  )
}
