import {useDispatch, useSelector} from 'react-redux'
import {useRoomOfferUrl} from 'hooks/useRoomOfferCheckoutLink'
import {getOfferContextFromRoomContext} from 'middleware/analytics/contexts/roomContext'
import {getRoomsActiveFilters} from 'modules/accommodation/selectors'
import {getRoomDetailOverlayVisibility} from 'modules/overlay/selectors'
import {getIsLockedDealExcludingGHA} from 'modules/search/selectors'
import {v4 as uuidv4} from 'uuid'

import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  RoomContext,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {roomsFilterListToMap} from '@daedalus/core/src/room/business/utils/filters'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'

import {useIsRoomOfferAppLocked} from './useIsRoomOfferAppLocked'

const getEventEntitiy = ({
  isRoomOfferAppLocked,
  isLockedDeal
}: {
  isRoomOfferAppLocked: boolean
  isLockedDeal: boolean
}) => {
  if (isRoomOfferAppLocked) return Entity.AppExclusiveOffer
  if (isLockedDeal) return Entity.LockedRoomOffer
  return Entity.RoomOffer
}
export const useRoomOfferInteraction = ({
  hotelId,
  roomId,
  offer,
  component,
  roomContext
}: {
  hotelId: string
  roomId: string
  offer: Offer
  component: string
  roomContext: RoomContext
}) => {
  const dispatch = useDispatch()
  // Parsing redirectId straight from native search object because we don't want to de-memoise all selectors based on state query string.
  // Using getQueryStringObject instead of URLSearchParams because native parsing extracts param incorrectly (returns just first symbol).
  const urlRedirectId = getQueryStringObject()?.['redirectId']
  const isLockedDeal = useSelector(getIsLockedDealExcludingGHA(offer))
  const {isRoomOfferAppLocked, appDownloadLinkId} = useIsRoomOfferAppLocked({
    offerId: offer.id
  })

  const login = useLogIn()

  const redirectId =
    typeof urlRedirectId === 'string' ? urlRedirectId : uuidv4()
  const anonymousId = getAnonymousId()

  const offerUrl = useRoomOfferUrl({
    hotelId,
    roomId,
    offer,
    redirectId
  })

  const isRoomDetailsOverlayOpen = useSelector(getRoomDetailOverlayVisibility)

  const activeRoomsFilters = useSelector(getRoomsActiveFilters)

  const handleClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: getEventEntitiy({isLockedDeal, isRoomOfferAppLocked}),
        action: Action.Clicked,
        team: Team.Select,
        component,
        analyticsContext: {
          [AnalyticsContext.RoomContext]: roomContext,
          [AnalyticsContext.OfferContext]: getOfferContextFromRoomContext(
            roomContext,
            offer.id
          )
        },
        payload: {
          roomFilterApplied: activeRoomsFilters.length > 0,
          allSelectedRoomFilters: roomsFilterListToMap(activeRoomsFilters),
          ...(isRoomOfferAppLocked ? {bannerId: appDownloadLinkId} : {})
        },
        page: Page.Accommodation
      })
    )

    if (isLockedDeal) {
      login({
        anonymousId,
        flow: AuthenticationFlows.UnlockOverlay,
        sourceComponent: SourceComponentType.UnlockOverlay,
        unlockHotelId: hotelId
      })
      return
    }
    if (isRoomOfferAppLocked) return window.open(offerUrl)

    if (isRoomDetailsOverlayOpen) {
      // If the user is navigating back from the PP,
      // we don't want to show the roomDetailsOverlay.
      window.location.replace(offerUrl)
    } else {
      window.location.assign(offerUrl)
    }
  }

  return {handleClick}
}
